body {
    padding: 0;
    margin: 0;
}

:root {
    --borderWidth: 1px;
    --borderStyle: var(--borderWidth) solid black;
    --FGFill: hsl(96%, 100%, 50%);
    --FGFill: pink;
    --BGFill: yellow;
}

#smileyGrid-root {
    margin: 0px;
    width: 100vw;
    height: 100vh;
    width: calc(var(--vw, 1vw) * 100);
    height: calc(var(--vh, 1vh) * 100);
    box-sizing: border-box;
    padding: 12px;
    background: none;
    padding-bottom: 24px;
}

.border {
    border: var(--borderStyle);
}

.flex-container {
    display: flex;
    flex-wrap: wrap;
    background: var(--BGFill);
}

.row .flex-container,
.flex-container.row {
    flex-direction: row;
}

.row .flex-child {
    width: 50%;
}

.col .flex-container,
.flex-container.col {
    flex-direction: column;
}

.col .flex-child {
    height: 50%;
}

.flex-child {
    flex-grow: 1;
    margin: calc(-1 * var(--borderWidth));
}

.flex-content {
    flex: 1;
}


/* TODO: the positioning of elements within face is jankey */

.face {
    background: var(--FGFill);
    display: flex;
    animation: inflate 4s ease-in-out infinite forwards;
}

.face:before {
    border-left: var(--borderStyle);
    border-right: var(--borderStyle);
    content: "";
    height: 12%;
    width: 40%;
    position: relative;
    left: 30%;
    top: 30%;
}

.face .smile {
    height: 20%;
    width: 40%;
    position: relative;
    top: 60%;
    left: -10.2%;
    display: flex;
    overflow: hidden;
}

.face .smile:before {
    border: var(--borderStyle);
    height: 200%;
    width: 100%;
    top: -105%;
    content: "";
    border-radius: 50%;
    position: relative;
    box-sizing: border-box;
}